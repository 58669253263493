import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import tw from "tailwind.macro";

import Container from "components/container";
import Footer from "components/footer";
import Header from "components/header";
import Layout from "components/layout";
import Metadata from "components/metadata";
import Title from "components/title";

const createMarkp = html => {
    return {
        __html: html
    };
};

const InnerStyled = styled.div`
    ${tw`lg:w-4/5 xl:w-2/3 xxl:w-1/2`};
`;

const Page = ({ data: { prismicPage } }) => {
    const { data } = prismicPage;
    return (
        <Layout cssClass="page">
            <Header />
            <Metadata
                title={data.seo_title}
                description={data.seo_description}
            />
            <Container size="small">
                <InnerStyled>
                    <Title>{data.title}</Title>
                    <div
                        dangerouslySetInnerHTML={createMarkp(data.content.html)}
                    />
                </InnerStyled>
            </Container>
            <Footer />
        </Layout>
    );
};

export const query = graphql`
    query($slug: String!) {
        prismicPage(uid: { eq: $slug }) {
            data {
                title
                seo_title
                seo_description
                content {
                    html
                }
            }
        }
    }
`;

export default Page;

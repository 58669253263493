import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tw from "tailwind.macro";

const TitleStyled = styled.h1`
    ${tw`font-sansbold antialiased leading-none mb-6 text-5xl md:text-7xl xl:mb-12`};
`;

const Title = ({ children }) => <TitleStyled>{children}</TitleStyled>;

Title.propTypes = {
    children: PropTypes.node
};

export default Title;
